import { render, staticRenderFns } from "./Invoices.vue?vue&type=template&id=252b7b58&scoped=true&"
import script from "./Invoices.vue?vue&type=script&lang=ts&"
export * from "./Invoices.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "252b7b58",
  null
  
)

export default component.exports