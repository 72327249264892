import ApiService from "@/api/api.service";
import { AxiosPromise } from "axios";
import { PaginationResponse } from "@/types/PaginationResponse";
import { Invoice } from "@/types/Invoice";

interface InvoicesFilters {
  email?: string;
  database?: number;
}

export const loadInvoices = (
  page: number,
  filters: InvoicesFilters
): AxiosPromise<PaginationResponse<Invoice[]>> => {
  return ApiService.query("api/admin/invoices", {
    page,
    email: filters.email,
    database: filters.database
  });
};

export const depositInvoice = (id: number): AxiosPromise => {
  return ApiService.put(`api/admin/invoices/deposit/${id}`, {});
};
